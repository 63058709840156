.mod-quota{
    .app-icon.rmd-icon{
        color: #424242;
        font-size: 48px;
    }
    .rmd-button.sm{
        width: 20px;
        height: 20px;
        .rmd-icon{
            font-size: 12px;
        }
    }
    .produk-logo{
        width: 48px;
        height: 48px;
        object-fit: cover;
    }
}